import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import Home from "./Components/Home/Home";
import ReactComponents from "./Components/ReactComponents/ReactComponents";
import ControlPanel from "./Components/Admin/ControlPanel";
import Admin from "./Components/Admin/Admin";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/react" element={<ReactComponents />} />          
          <Route path="/ferter3006" element={<Admin />} />
          <Route path="/controlpanel" element={<ControlPanel />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
