import React, { useEffect, useState } from 'react'
import { colors } from '../../Media/colors'
import { useDispatch, useSelector } from 'react-redux';
import TarjetaComponente from './TarjetaComponente';
import ListaComponentes from './ListaComponentes';
import { setComponents } from '../../Redux/appActions';


function ContenedorComponents() {

    const appState = useSelector(state => state.appState)
    const dispatch = useDispatch()

    const [active, setActive] = useState(null)
    const [activeKey, setActiveKey] = useState(0)
    const [serverErrorMessage, setError] = useState('')

    useEffect(() => {        
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/componentes`, {
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {                
                dispatch(setComponents(jsonResponse.componentes))
                setActive(jsonResponse.componentes[0])
                if (jsonResponse.status !== 1) { setError(jsonResponse.message) }
            })
    }, [])

    return (
        <div style={{ backgroundColor: colors.blackFondo, color: "white", paddingTop: "50px" }}>
            <div className='row' style={{ padding: '45px 35px 15px 25px', margin: '0px', backgroundColor: colors.blackFondo }}>
                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12' style={{ padding: '6px', textAlign: 'start', marginBottom: '15px' }} >
                    <div className='row'>
                        {appState.components ? appState.components.map((componente, index) => (                            
                            <ListaComponentes key={index} componente={componente} setActive={setActive} activeKey={activeKey} setActiveKey={setActiveKey} index={index} />                                                        
                        ))
                            : <>
                                <div style={{ color: 'red', paddingLeft: '5%' }}>
                                    <div className='row'>
                                        <h5>Server Error</h5>
                                    </div>                                    
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className='col-xl-7 col-lg-8 col-md-9 col-sm-12 col-12' style={{ padding: '6px', textAlign: 'start', marginBottom: '15px' }} >
                    {appState.components ? <TarjetaComponente componente={active} />
                        : <>
                            <div style={{ color: 'red', paddingLeft: '5%' }}>
                                <div className='row'>
                                    <h5>Por alguna razón no se pueden recibir los proyectos del servidor</h5>
                                </div>
                                <div className='row'>
                                    <p>{serverErrorMessage}</p>
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>


        </div>
    )
}

export default ContenedorComponents