import React from 'react'
import { mdiGithub } from '@mdi/js';
import { mdiInstagram } from '@mdi/js';
import { mdiGmail } from '@mdi/js';
import Tarjeta from './Tarjeta';

function Tarjetero() {

    const targetas = [

        {
            name: 'Instagram',
            icon: mdiInstagram,
            color: 'purple',
            text: '/ferter3006',
            link: 'https://www.instagram.com/ferter3006'
        },
        {
            name: 'GitHub',
            icon: mdiGithub,
            color: '#333333',
            text: '/ferter3006',
            link: 'https://www.github.com/ferter3006'
        },
        // {
        //     name: 'Youtube',
        //     icon: mdiYoutube,
        //     color: 'red',
        //     text: '/@fertervideos',
        //     link: 'https://www.youtube.com/@fertervideos'
        // },
        {
            name: 'Email',
            icon: mdiGmail,
            color: '#b23121',
            text: 'ferratersimon@gmail.com',
            link: 'mailto:ferratersimon@gmail.com'
        }

    ]

    // const iconos = [mdiEmailVariant, mdiYoutube, mdiGithub, mdiInstagram]
    // const colors = ['green', 'red', 'black', 'purple']


    return (
        <>
            <div className='row' style={{ marginRight: '5px',marginLeft:'2px' }}>
                {/* <button style={{ backgroundColor: colors.blackFondo, color: 'white',border: '2px solid white', borderBottom: 'none', borderRadius: '15px 0px 0px 0px'  }} >Contact</button> */}
                Encuentrame en
            </div>
            <div className='row' style={{ marginRight: '5px' }}>
                {targetas.map((targeta, index) => (
                    <Tarjeta key={index} icono={targeta.icon} color={targeta.color} text={targeta.text} link={targeta.link}/>
                ))}
            </div>
        </>
    )
}

export default Tarjetero