import React from 'react'

function ListaCodes({ token, codes, setEditcode, refresh, setRefresh }) {
    const btn = { width: '80px', padding: '5px', margin: '1px' }

    const handleDelete = (code) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/componente_code/${code.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    setRefresh(refresh + 1)
                } else {
                    alert(`Server Error: ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div>
                {codes.map((code, index) => (
                    <div key={index} className='row' style={{ display: 'flex', alignItems: 'center' }}>
                        <button className='btn btn-success' style={btn} onClick={() => setEditcode(code)} >Edit</button>
                        <button className='btn btn-danger' style={btn} onClick={() => handleDelete(code)}>Delete</button>
                        <div style={{ marginLeft: '15px' }}>
                            Id: {code.id} - Component Id: {code.react_component_id} - filename: {code.filename}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ListaCodes