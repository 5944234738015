import React from 'react'
import Header from '../Header/Header'
import NavTop from '../NavTop/NavTop'
import ContenedorComponents from './ContenedorComponents'

function ReactComponents() {
  return (
    <>
      <NavTop page={'react'} />
      <Header/>
      <ContenedorComponents />
    </>
  )
}

export default ReactComponents