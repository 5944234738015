import React, { useState } from 'react'
import Icon from '@mdi/react';
import { colors } from '../../Media/colors';


function Tarjeta({ icono, color, text, link }) {

    const [flip, setFlip] = useState(false)
    const [flop, setFlop] = useState(false)


    const front = <Icon path={icono} size={2} />
    const back = <a className='otro-flip' href={link} style={{color: 'white', fontWeight: '600', }}>{text}</a>

    const handleCardClick = (e) => {

        setFlip(!flip)
        setTimeout(() => {
            setFlop(!flop)
        }, 250);

    }

    return (
        <div className='col-lg-4 col-md-6 col-sm-4 col-4' style={{ padding: '5px', textAlign: 'center' }}>
            <div className="flip-card" onClick={handleCardClick}>
                <div className="flip-card-inner">
                    <div className={flip ? 'flip-card-back' : 'flip-card-front'} style={{justifyContent:'center', alignItems:'center', display: 'flex', height: '100px', margin: '1px', backgroundColor: color, padding: '25px 0px 25px 0px', border: `2px solid ${colors.grisBorder}`, borderRadius: '15px' }}>
                        {flop ? back : front}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tarjeta