import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ControlComponentes from './ControlComponentes'
import ControlProyectos from './ControlProyectos'
import NavAdmin from './NavAdmin'

function ControlPanel() {

    const location = useLocation()
    const [token, setToken] = useState('')
    const [view, setView] = useState('nada')

    useEffect(() => {

        if (location.state) {
            setToken(location.state.token)
        } else {
            alert('no hay token')
        }
    }, [])

    if (!token) {
        return <h6 style={{ color: 'red', margin: '15px' }}>Unauthenticated moderfuker</h6>
    }

    return (
        <>
            <NavAdmin view={view} setView={setView} />
            {view === 'nada' ? <h3 className='container bg-danger'>Welcome to control Panel</h3> : null}
            {view === 'proyectos' ? <ControlProyectos token={token} /> : null}
            {view === 'componentes' ? <ControlComponentes token={token} /> : null}

        </>
    )
}

export default ControlPanel