import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { colors } from '../../Media/colors'

function TarjetaComponente({ componente }) {

    if (!componente) { return <h5>no hay props en TarjetaComponente</h5> }

    const handleCopy = (code, index) => {
        const boton = document.getElementById(`btn${index}`)
        navigator.clipboard.writeText(code)
        boton.innerHTML = '¡Hecho!'
        setTimeout(() => {
            boton.innerHTML = 'Copiar código'
        }, 500);
    }
    return (
        <>
            <div style={{ textAlign: 'start', marginBottom: '15px' }} >
                <div style={{ backgroundColor: colors.blackNoTanto, borderRadius: '0px 25px 0px 0px', color: 'white', padding: '3px' }}>
                    <div style={{ justifyContent: '', display: 'flex', alignItems: 'center' }}>
                        <div className='petitLogoCard' style={{ width: '30px', height: '30px', borderRadius: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={require(`../../Media/png/react.png`)} style={{ width: '24px' }} alt={'rect-logo'} />                            
                        </div>
                        <div style={{ fontSize: '12px', color: 'grey', marginLeft: '5px' }}>
                            React components by Ferter
                        </div>
                    </div>
                </div>
                <div style={{ height: '85%', backgroundColor: colors.grisBorder, color: 'white', padding: '5px 0px 5px 15px', border: `2px solid ${colors.grisBorder}`, borderRadius: '0px 0px 5px 5px' }}>
                    <h2 style={{ fontSize: '15px', paddingBottom: '5px', paddingTop: '8px', fontWeight: '800' }}>{componente.title}</h2>
                    <p className='title2' style={{ fontSize: '14px' }}>{componente.description}</p>
                    <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                    </div>
                    {componente.react_component_code.map((porcion, index) => (
                        <div key={index} style={{ color: colors.grisAzulao, fontSize: '13px' }}>
                            <div style={{ margin: '5px' }}>
                                {porcion.filename}
                            </div>
                            <SyntaxHighlighter language="javascript" style={docco} showLineNumbers={true} customStyle={{ borderRadius: '15px', backgroundColor: colors.amarilloLogo, marginRight: '12px' }} >
                                {porcion.code}
                            </SyntaxHighlighter>
                            <div style={{ justifyContent: 'end', display: 'flex', marginBottom: '12px', paddingRight: '15px' }}>
                                <button id={`btn${index}`} className='btn-sm btn-dark' style={{ paddingRight: '10px', width: '125px' }} onClick={() => handleCopy(porcion.code, index)}>Copiar código</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}


export default TarjetaComponente

