import React, { useEffect, useState } from 'react'

function FormComponentes({ token, refresh, setRefresh, editComp }) {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [id, setId] = useState(0)


    useEffect(() => {
        if (editComp) {
            setId(editComp.id)
            setTitle(editComp.title)
            setDescription(editComp.description)
        }
    }, [editComp])

    const handleSend = (action) => {
        let fetchaki = `${process.env.REACT_APP_API_ENDPOINT}/new_component`
        let actionaki = 'POST'
        if (action === 'update') {
            fetchaki = `${process.env.REACT_APP_API_ENDPOINT}/componente`
            actionaki = 'PUT'
        }
        fetch(fetchaki, {
            method: actionaki,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                id,
                title,
                description
            })
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    setTitle('')
                    setDescription('')
                    setId(0)
                    setRefresh(refresh + 1)
                } else {
                    alert(`server error: ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div className='mt-5 mb-5 pb-5'>
            <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        Id *soloparaupdate
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' rows={1} value={id} onChange={(e) => setId(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        title
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' rows={1} value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        description
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>

                <button className='btn btn-primary mr-5' onClick={() => handleSend('new')}>Create new Row</button>
                <button className='btn btn-warning' onClick={() => handleSend('update')}>Update actual Row</button>

            </div>
        </>
    )
}

export default FormComponentes