import React from "react";
import { colors } from "../../Media/colors";
import EfectoEscritura from "../EfectoEscritura/EfectoEscritura";
import Tarjetero from "../Tarjeta/Tarjetero";

function Header() {
  return (
    <>
      <div style={{ backgroundColor: colors.blackFondo, color: "white", paddingLeft: "5%", paddingTop: "50px", paddingRight: '25px' }}>
        <div className="row" >
          <div className="col-12">

            <h1 style={{ fontWeight: "800", letterSpacing: "2px", color: colors.amarilloLogo, }}>ferter.es</h1>
          </div>
        </div>
        <div className="row" >
          <div className="col-4">
            <h5 style={{ marginTop: '-15px', fontSize: '15px', letterSpacing: '3px', paddingLeft: '5px' }}>&gt;<EfectoEscritura text={['portfolio personal', 'programación autodidacta', 'BackEnd', 'FrontEnd', 'FullStack', 'html', 'css', 'Javascript', 'ReactJs', 'Laravel', 'React Native', 'Node Js', 'PHP', 'Python', 'Express', 'Others...']} /></h5>
          </div>
        </div>
        <div className="row" >
          <div className="col-md-6">
            <p style={{ marginTop: '35px' }}>Bienvenid@ a ferter.es, mi sitio personal en el que encontrarás varios de mis proyectos con los que he estado trabajando; Aprendiendo y peleándome con los lenguajes de programación de una forma autodidacta.</p>
            <p>Además encontrarás una pequeña galería de video-tutoriales que he desarrollado a lo largo de los años, Así como mi galería personal de componentes de React Js de utilidad.</p>
          </div>
          <div className="col-md-6">
            <Tarjetero />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
