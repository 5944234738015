import React, { useEffect, useState } from 'react'
import TarjetaProyecto from './TarjetaProyecto'
import { colors } from '../../Media/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setProjects } from '../../Redux/appActions';

function Proyectos() {

    const appState = useSelector(state => state.appState)
    const dispatch = useDispatch()
    const [serverErrorMessage, setError] = useState('')

    useEffect(() => {        
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/proyectos`, {
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {                
                dispatch(setProjects(jsonResponse.proyectos))                
                if (jsonResponse.message) { setError(jsonResponse.message) }
            })
    }, [])

    return (
        <div className='row' style={{ padding: '45px 35px 15px 25px', margin: '0px', backgroundColor: colors.blackFondo }}>

            {appState.projects ? appState.projects.map((proyecto, index) => (
                <TarjetaProyecto key={index} proyecto={proyecto} />
            ))
            : <>
            <div style={{color: 'red', paddingLeft:'5%'}}>
            <div className='row'>
            <h5>Por alguna razón no se pueden recibir los proyectos del servidor</h5>
            </div>
            <div className='row'>
            <p>{serverErrorMessage}</p>
            </div>
            </div>
            </>
            }
        </div>
    )
}

export default Proyectos