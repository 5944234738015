import React from 'react'
import { colors } from '../../Media/colors'

function TarjetaProyecto({ proyecto }) {
    
    return (
        <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 projectCard' style={{ padding: '6px', textAlign: 'start', marginBottom: '15px' }} >
            <div className='detallesHold' style={{ borderBottom: '0px', borderRadius: '15px 15px 0px 0px', color: 'black', paddingLeft: '15px', fontSize: '12px', height: '8px' }}>
            </div>
            <div style={{ height: '85%', backgroundColor: colors.grisBorder, color: 'white', padding: '5px 0px 5px 15px', border: `2px solid ${colors.grisBorder}`, borderRadius: '5px 5px 0px 0px' }}>
                <h2 style={{ fontSize: '15px', paddingBottom: '5px', paddingTop: '8px', fontWeight: '800' }}>{proyecto.title}</h2>
                <p className='title2' style={{ fontSize: '14px' }}>{proyecto.title2}</p>
                <p style={{ color: colors.grisAzulao, fontSize: '13px' }}>{proyecto.description}</p>
            </div>            
            <div style={{ backgroundColor: colors.blackNoTanto, borderRadius: '0px 0px 25px 0px', color: 'white', padding: '3px' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                    <div className='petitLogoCard' style={{ width: '30px', height: '30px', borderRadius: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {require(`../../Media/png/${proyecto.tecnologies}.png`) ? <img alt={`logo-${proyecto.tecnologies}`} src={require(`../../Media/png/${proyecto.tecnologies}.png`)} style={{ width: '24px' }} /> : null }
                        
                    </div>
                    <div>
                        {proyecto.git ? <a href={proyecto.linkgit} rel={'noopener noreferrer'} target={'_blank'}><button className='btn-sm btn-dark' style={{ borderRadius: ' 22px 1px 1px 1px' }} >GitHub</button></a> : null}
                        <a href={proyecto.link} target={'_blank'} rel={'noopener noreferrer'} >
                            <button className='btn-sm btn-dark' style={{ borderRadius: ' 1px 1px 22px 1px', paddingRight: '10px' }}>{proyecto.type}</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TarjetaProyecto