import React from "react";
import NavTop from "../NavTop/NavTop";
import '../../App.css'
import Header from "../Header/Header";
import Proyectos from "../Proyectos/Proyectos";

function Home() {

  return (
    <>
      <NavTop page={'home'} />       
      <Header />
      <Proyectos />
    </>
  );
}

export default Home;
