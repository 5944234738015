export const colors = {
  azulClaro: "#76b5c5",
  azulFuerte: "#154c79",

  azulReact: '#61DBFB',
  
  azulLogo: "#2f45aa",  
  amarilloLogo: '#ffd401',

  blackFondo: '#000',
  blackNoTanto: '#252525',

  grisBorder: '#1f1919',
  grisAzulao: '#9E9DA7',


};
