import React, { useEffect, useState } from 'react'

function FormCodes({ token, refresh, setRefresh, editCode }) {

    const [filename, setFilename] = useState('')
    const [code, setCode] = useState('')
    const [id, setId] = useState(0)
    const [react_component_id, setreact_component_id] = useState(0)

    useEffect(() => {
        if (editCode) {
            setId(editCode.id)
            setreact_component_id(editCode.react_component_id)
            setFilename(editCode.filename)
            setCode(editCode.code)
        }
    }, [editCode])

    const handleSend = (action) => {
        let fetchaki = `${process.env.REACT_APP_API_ENDPOINT}/new_component_code`
        let actionaki = 'POST'
        if (action === 'update') {
            fetchaki = `${process.env.REACT_APP_API_ENDPOINT}/component_code`
            actionaki = 'PUT'
        }
        fetch(fetchaki, {
            method: actionaki,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                id,
                react_component_id,
                filename,
                code
            })
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    setRefresh(refresh + 1)
                } else {
                    alert(`Server error: ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div className='mt-5 mb-5 pb-5'>
                <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        Id *soloparaupdate
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' rows={1} value={id} onChange={(e) => setId(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        Component ID
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' rows={1} value={react_component_id} onChange={(e) => setreact_component_id(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        filename
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' rows={1} value={filename} onChange={(e) => setFilename(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-1 mb-2'>
                    <div className='col-2'>
                        code
                    </div>
                    <div className='col-8'>
                        <textarea className='form-control' rows={16} value={code} onChange={(e) => setCode(e.target.value)} />
                    </div>
                </div>

                <button className='btn btn-primary mr-5' onClick={() => handleSend('new')}>Create new Row</button>
                <button className='btn btn-warning' onClick={() => handleSend('update')}>Update actual Row</button>

            </div>
        </>
    )
}

export default FormCodes