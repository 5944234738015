import React, { useEffect, useState } from 'react'

function FormProyectos({ edit, token, setRefresh, refre }) {

    // Inputs de crean dinamicamente en base a este arrey
    const helper = ['id','type', 'title', 'title2', 'description', 'tecnologies', 'link', 'git', 'linkgit']
    const [stateProject, setStateProject] = useState({})

    // si se click a "edit" desde ControlProyectos... ponemos valores en los inputs
    useEffect(() => {
        const allInputs = {}
        helper.map((cosa, index) => (
            allInputs[cosa] = edit ? edit[cosa] ? edit[cosa] : cosa === 'git' ? '0' : '' : ''
        ))
        setStateProject(allInputs)
    }, [edit])

    // actualizacion de estado onChange los inputs
    const handleChange = (e) => {
        const tempState = stateProject
        tempState[e.target.id] = e.target.value
        setStateProject({
            ...stateProject,
            [e.target.id]: e.target.value
        })
    }

    // enviamos, no se acualiza, para más seguridad se crea otro y ya luego borras el antiguo
    const handleSend = (action) => { 
        let fetchHere = `${process.env.REACT_APP_API_ENDPOINT}/new_proyecto`
        let methodHere = 'POST'
        if (action === 'update') {            
            fetchHere = `${process.env.REACT_APP_API_ENDPOINT}/proyecto`
            methodHere = 'PUT'
        }
        fetch(fetchHere, {
            method: methodHere,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(stateProject)
        })
            .then(response => response.json())
            .then(jsonResponse => {
                console.log(jsonResponse);
                if (jsonResponse.status === 1) {                    
                    setRefresh(refre + 1)
                } else {
                    alert(`server error: ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div className='mt-5 mb-5 pb-5'>
                {helper.map((cosa, index) => (
                    <div key={index} className='row mt-1 mb-2'>
                        <div className='col-2'>
                            {cosa}
                        </div>
                        <div className='col-8'>
                            <textarea id={cosa} className='form-control' rows={cosa === 'description' ? 5 : 1} placeholder={cosa !== 'git' ? 'string' : '0 or 1'} onChange={handleChange} value={stateProject ? stateProject[cosa] : ''} />
                        </div>
                    </div>
                ))}
                <div className='row'>

                <button className='btn btn-primary col-2 mr-2' onClick={()=>handleSend('new')}>Create New Row</button>                
                <button className='btn btn-warning col-2' onClick={()=>handleSend('update')}>Update Actual Row</button>
                </div>
            </div>

        </>
    )
}

export default FormProyectos