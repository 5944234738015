import React from 'react'

function ListaComponentes({ componente, setActive, activeKey, setActiveKey, index }) {

const handleClick =() => {
    setActive(componente)
    setActiveKey(index)
}

//<button className={active === index ? 'btn btn-dark btn-comp-active' : 'btn btn-dark btn-comp'} style={{ borderRadius: '2px 12px 12px 2px', paddingRight: '10px', width: '80%' }} onClick={() => setActive(componente)}>
    return (
        <div className='col-md-12 col-md-12 text-center d-none d-md-block' style={{ margin: '3px' }}>
            <button className={activeKey === index ? 'btn btn-dark btn-comp-active' : 'btn btn-dark btn-comp'} style={{ borderRadius: '2px 12px 12px 2px', paddingRight: '10px', width: '80%' }} onClick={handleClick} >
                {componente.title}
            </button>
        </div>
    )
}

export default ListaComponentes