import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../Media/colors'

function AdminLogin() {

    const shareStyle = { margin: '12px' }
    const [mail, setMail] = useState('ferratersimon@gmail.com')
    const [pass, setPass] = useState('')

    const navigate = useNavigate()

    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/loginuser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'email': mail,
                'password': pass
            })
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    navigate('/controlpanel', {
                        state: {
                            token: jsonResponse.token
                        }
                    })
                } else {
                    alert(`Server error: ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div style={{ backgroundColor: colors.blackFondo, color: "white", paddingLeft: "5%", paddingTop: "50px", paddingRight: '25px' }}>
                <div className="row" >
                    <div className="col-12">
                    </div>
                </div>
                <div className="row" >
                    <div className="col-4">
                        <input className='form-control' placeholder='mail' style={shareStyle} onChange={(e) => setMail(e.target.value)} />
                        <input className='form-control' type='password' placeholder='pass' style={shareStyle} onChange={(e) => setPass(e.target.value)} />
                        <button className='btn btn-primary' style={shareStyle} onClick={handleSubmit} >Enter</button>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-6">

                    </div>
                </div>
            </div>


        </>
    )
}

export default AdminLogin