const initialState = {
  projects: [],
  components: [],  
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_STATE':
      return {
        ...state,
        user: action.payload
      }
    case 'SET_PROJECTS':
      return {
        ...state,
        projects: action.payload
      }
      case 'SET_COMPONENTS':
        return {
          ...state,
          components: action.payload
        }
    default:
      return state
  }
}