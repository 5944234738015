import React from 'react'
import AdminLogin from './AdminLogin'

function Admin() {
  return (
    <>
    <div className='navbar' style={{backgroundColor:'red', borderBottom:'7px solid black'}}>
        <h6>Admin</h6>
    </div>    
    <AdminLogin/>
    </>
  )
}

export default Admin