import React, { useEffect, useState } from 'react'
import FormCodes from './FormCodes'
import FormComponentes from './FormComponentes'
import ListaCodes from './ListaCodes'
import ListaComponentes from './ListaComponentes'

function ControlComponentes({ token }) {

    const [componentes, setComponentes] = useState(null)
    const [codes, setCodes] = useState(null)
    const [refresh, setRefresh] = useState(0)
    const [editComp, setEditComp] = useState(null)
    const [editCode, setEditcode] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/componentes_admin`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    setComponentes(jsonResponse.componentes)
                    setCodes(jsonResponse.codes)
                } else {
                    alert(`server error: ${jsonResponse.message}`)
                }
            })
    }, [refresh])

    return (
        <div className='container mt-5'>
            <h5>Control componentes</h5>
            {componentes ? <ListaComponentes token={token} componentes={componentes} setEditComp={setEditComp} refresh={refresh} setRefresh={setRefresh}/> : null}
            <FormComponentes token={token} refresh={refresh} setRefresh={setRefresh} editComp={editComp} />
            <h5>Control CODES of componentes</h5>
            {codes ? <ListaCodes token={token} codes={codes} setEditcode={setEditcode} refresh={refresh} setRefresh={setRefresh} /> : null}
            <FormCodes token={token} refresh={refresh} setRefresh={setRefresh} editCode={editCode} />
        </div>
    )
}

export default ControlComponentes