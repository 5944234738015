import React, { useEffect, useState } from 'react'
import FormProyectos from './FormProyectos';

function ControlProyectos({ token }) {

    const [proj, setProj] = useState(null)
    const [edit, setEdit] = useState(null)
    const [refre, setRefresh] = useState(0)
    const btn = { padding: '5px', margin: '1px', marginRight: '5px', width: '80px' }

    // pedimos los tados de los proyectos, lo del refre es lo de mi amigo enemigo de siempre XD
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/proyectos`, {
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {
                setProj(jsonResponse.proyectos)
                if (jsonResponse.message) { alert(jsonResponse.message) }
            })
    }, [refre])

    const handleEditBtn = (proyecto) => {
        setEdit(proyecto)
    }

    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/proyecto/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    setRefresh(refre + 1)
                } else {
                    alert(`server error ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div className='container' style={{ marginTop: '50px', paddingTop:'50px' }}>
                <h2>Projects Control Panel</h2>
                {proj ? proj.map((proyecto, index) => (
                    <div key={index} className='row'>
                        <button className='btn btn-success' style={btn} onClick={() => handleEditBtn(proyecto)}>Edit</button>
                        <button className='btn btn-danger' style={btn} onClick={() => handleDelete(proyecto.id)}>Delete</button>
                        {proyecto.id} - {proyecto.title}
                    </div>
                )) : null}
                <FormProyectos edit={edit} token={token} setRefresh={setRefresh} refre={refre} />
            </div>

        </>
    )
}

export default ControlProyectos