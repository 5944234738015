import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../Media/colors";

function NavTop({ page }) {
  
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark" style={{  paddingLeft: "10%", backgroundColor: colors.blackFondo, borderBottom: `2px solid ${colors.grisBorder}` }} >
        <Link className="navbar-brand" to={"/"}>
          <img src={require("../../Media/logo.jpg")} style={{ width: "26px", marginRight: "10px", borderRadius: "25px", marginBottom: "5px", }} alt={'ferter logo'} />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className={page === 'home' ? "nav-item active" : "nav-item"}>
              <Link className="nav-link text-monospace" to={"/"}>
                Home
              </Link>
            </li>
            <li className={page === 'react' ? "nav-item active" : "nav-item"}>
              <Link className="nav-link text-monospace myLink" to={"/react"}>
              &lt;React&gt; 
              </Link>
            </li>      
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavTop;
