import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../Media/colors";

function NavAdmin({ view, setView }) {
  
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark" style={{  paddingLeft: "10%", backgroundColor: colors.blackFondo, borderBottom: `2px solid ${colors.grisBorder}` }} >
        <Link className="navbar-brand" to={"/"}>
          <img src={require("../../Media/logo.jpg")} style={{ width: "26px", marginRight: "10px", borderRadius: "25px", marginBottom: "5px", }} alt={'ferter logo'} />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">            
            <li className={view === 'proyectos' ? "nav-item active" : "nav-item"}>
              <a className="nav-link text-monospace myLink" onClick={()=>setView('proyectos')}>
              Proyectos
              </a>
            </li>
            <li className={view === 'componentes' ? "nav-item active" : "nav-item"}>
              <a className="nav-link text-monospace myLink" onClick={()=>setView('componentes')} >
              Componentes
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavAdmin;