export const setUSerState = user => {
  return {
    type: 'SET_USER_STATE',
    payload: user
  }
}

export const setProjects = (projects) => {
  return {
    type: 'SET_PROJECTS',
    payload: projects
  }
}

export const setComponents = (projects) => {
  return {
    type: 'SET_COMPONENTS',
    payload: projects
  }
}

  // all otehr actions u need here
  // with the same format

