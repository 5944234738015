import React from 'react'

function ListaComponentes({ token, componentes, setEditComp, refresh, setRefresh }) {
    const btn = { width: '80px', padding: '5px', margin: '1px' }   
    
    const handleDelete = (componente) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/componente/${componente.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    setRefresh(refresh + 1)
                } else {
                    alert(`Server Error: ${jsonResponse.message}`)
                }
            })
    }

    return (
        <>
            <div>
                {componentes.map((componente, index) => (
                    <div key={index} className='row' style={{ display: 'flex', alignItems: 'center' }}>
                        <button className='btn btn-success' style={btn} onClick={()=>setEditComp(componente)}>Edit</button>
                        <button className='btn btn-danger' style={btn} onClick={()=>handleDelete(componente)}>Delete</button>
                        <div style={{ marginLeft: '15px' }}>
                            Id: {componente.id} - {componente.title}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ListaComponentes